ion-content {
  --ion-background-color: #f9fafe;
 // --padding-top: 20px;
  text-transform: capitalize !important;
}

:host {
  --background: #f9fafe !important;
  --padding-top: 20px;
  --text-transform: capitalize !important;
  text-transform: capitalize !important;
}

.ticket-layout {
  background: #f9fafe !important;
}

.mt-10 {
  margin-top: 10px;
}

.notifications-app .modal-title {
  font-size: 1.2rem;
  padding-top: 20px;
}

/*
    * App Global CSS
    * ----------------------------------------------------------------------------
    * Put style rules here that you want to apply globally. These styles are for
    * the entire app and not just one component. Additionally, this file can be
    * used as an entry point to import other CSS/Sass files to be included in the
    * output CSS.
    * For more information on global stylesheets, visit the documentation:
    * https://ionicframework.com/docs/layout/global-stylesheets
    */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@ng-select/ng-select/themes/default.theme.css";


// regular style toast
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  font-size: 1rem !important;
}
.green{
  color:green;
}
.gray{
  color: gray;
}
.darkGrey{
  color: darkgrey;
}
.red {
  color: red;
}
.yellow {
  color: yellow;
}
.blue {
  color: blue;
}
.purple{
  color: purple;
}

.btn.btn-success,
.ion-card-subtitle.btn.btn-success {
  color: #000 !important;
  border: 1px solid #28a745 !important;
}

.btn.btn-success:hover,
.ion-card-subtitle.btn.btn-success:hover {
  box-shadow: 0 0 15px #28a745;
  transition: all 0.5s ease;
  border: 1px solid #28a745 !important;
}

.btn.btn-success:hover span,
.ion-card-subtitle.btn.btn-success:hover i {
  color: #000 !important;
  text-shadow: 0 0 15px #28a745;
  transition: all 0.5s ease;
}

.pr-0 {
  padding-right: 0px !important;
}

.input-group-append .btn {
  height: 38px;
}

.pl-0 {
  padding-left: 0px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}

.error-msg {
  color: #ca3737;
}

.fa-picture-o {
  font-size: 200px;
  color: #ccc;
}

.divider {
  margin-top: 20px;
}

.action-width {
  min-width: 110px;
  // max-width: 150px;
}

.table-header-style {
  background-color: #6e5694;
  color: white;
}

.signout-icon {
  font-size: 24px;
  color: #ca3737;
}

.fa-close {
  font-size: 16px;
  position: relative;
  top: 15px;
}

.submit-button-container {
  padding: 50px 0px;
}

.submit-button {
  // background-color: #6e5694;
  color: #fff;
  line-height: 2;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    padding: 4px;
  }
}

.submit-button[disabled] {
  color: #fff;
}

.submit-button:hover {
  color: #fff;
}

ion-toast.custom-toast {
  --background: #f4f4fa;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #4b4a50;
}

ion-toast.custom-toast::part(message) {
  font-style: italic;
}

ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: #030207;
  font-size: 15px;
}

.update-button {
  text-decoration: none;
  width: 64px;
  padding: 7px;
  text-transform: capitalize;

  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
}

.action-width {
  min-width: 110px;
}

.notes-width {
  max-width: 150px;
  word-break: break-all;
}

.des-width {
  max-width: 200px;
  word-break: break-all;
}

.search-results {
  height: 10vh;
  overflow: scroll;
}

.container.table-responsive.mobile-pad {
  height: calc(100vh - 14.7rem) !important;
}

.container.table-responsive.mobile-pad::-webkit-scrollbar {
  width: 0.25rem !important;
}

.container.table-responsive.mobile-pad::-webkit-scrollbar-thumb {
  background: #c2c3c3 !important;
}

@media screen and (max-width: 800px) {
  .alert.mb-0 {
    font-size: 12px !important;
  }
  .pr-30 {
    padding-right: 15px !important;
  }
  .pl-30 {
    padding-left: 15px !important;
  }

  .container.table-responsive.mobile-pad {
    height: calc(100vh - 16.7rem) !important;
  }

  thead.table-header-style tr th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
  }

  .container.table-responsive.mobile-pad {
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.pull-right {
  float: right;
  position: relative;
  bottom: 9px;

  @media screen and (max-width: 800px) {
    bottom: 2px;
  }
}

.pull-left {
  float: left;
  position: relative;
  bottom: 9px;

  @media screen and (max-width: 800px) {
    bottom: 2px;
  }
}

ion-card {
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 25px;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.p-l-16 {
  padding-left: 16px;
}

.p-r-16 {
  padding-right: 16px;
}

.toolbar {
  contain: inherit;
  overflow: visible;
}

.clock-face__number > span.active,
.clock-face__clock-hand,
.timepicker__header {
  background: #6e5694 !important;
}

.timepicker-button {
  color: #6e5694 !important;
}

.h30px {
  height: 35px !important;
}

.h40px {
  height: 45px !important;
}

.card-content-md p {
  font-size: 13px !important;
}

.ion-card-title {
  display: inline-block !important;
  font-size: 17px !important;
  text-transform: capitalize;
}

.pull-right ion-button {
  display: inline-block !important;
  float: right;
  color: #fff;
  font-size: 12px;
  padding: 0px;
  width: 40px;
  height: 35px;
}

.pull-left ion-button {
  display: inline-block !important;
  float: left;
  color: #fff;
  font-size: 12px;
  padding: 0px;
  width: 40px;
  height: 35px;
}


.ion-card-subtitle {
  display: inline-block !important;
  float: right;
  color: #fff;
  font-size: 12px;
  margin: 0px;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.submit-button .dropdown-menu,
.dropdown-item {
  font-size: 12px;
  padding: 1px 15px;
}

.dropdown-divider {
  margin: unset;
}

.dropdown-item-text {
  font-size: 12px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.card-container-scroller {
  height: 90vh;
  width: 100% !important;
  max-width: 100% !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  overflow-y: scroll;
}

.search-container {
  padding: 0px 20px;
}

.submit-edit-button {
  background-color: #6e5694;
  color: white;
  size: 10px;
  height: 34px;
  position: relative;
  top: 5px;
  right: 8px;
}

.update-button {
  text-decoration: none;
  width: 64px;
  padding: 7px;
  text-transform: capitalize;

  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
}

.action-width {
  min-width: 110px;
}

.notes-width {
  max-width: 150px;
  word-break: break-all;
}

.des-width {
  max-width: 200px;
  word-break: break-all;
}

.search-results {
  height: 10vh;
  overflow: scroll;
}

.container.table-responsive.mobile-pad {
  height: calc(100vh - 14.7rem) !important;
}

.container.table-responsive.mobile-pad::-webkit-scrollbar {
  width: 0.25rem !important;
}

.container.table-responsive.mobile-pad::-webkit-scrollbar-thumb {
  background: #c2c3c3 !important;
}

@media screen and (max-width: 800px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: -1rem !important;
  }

  .container.table-responsive.mobile-pad {
    height: calc(100vh - 16.7rem) !important;
  }

  thead.table-header-style tr th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
  }

  .container.table-responsive.mobile-pad {
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ffffff !important;
  outline: 0;
  box-shadow: 0 0 0 0.025rem rgb(0 0 0) !important;
}
.container.mt-5.changepassword .mat-mdc-dialog-content,
.container.mt-5.myprofile .mat-mdc-dialog-content {
  overflow-x: hidden;
}

.container.mt-5.changepassword,
.container.mt-5.myprofile,
.header-panel-category {
  background: rgba(255, 255, 255);
  border-radius: 16px;

  box-shadow: 0 0 20px rgb(0 0 0 / 9%);

  backdrop-filter: blur(5px);

  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #9a9a9a33;
  overflow-x: hidden;
}

ion-card {
  background: rgba(255, 255, 255);

  margin-bottom: 5px;

  border-radius: 16px;
  margin-right: 20px;

  //    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);

  box-shadow: 0 0 20px rgb(0 0 0 / 9%);

  backdrop-filter: blur(5px);

  -webkit-backdrop-filter: blur(5px);

  border: 1px solid #9a9a9a33;
}

.ticket-card-content {
  padding-bottom: 0px;
}

.ticket-header {
  padding-bottom: 0px !important;
}

.max-h7 {
  max-height: 7x !important;
}

/*Global Search menu*/
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -2px;
}

.heightaccess {
  height: calc(100vh - 16rem) !important;
}

.justify-content-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  display: -webkit-flex;
  display: flex;
}

.justify-content-end button.mat-focus-indicator.btn.mat-button.mat-button-base {
  height: 36px;
  margin-right: 5px;
  margin-left: 5px;
}

.btn.btn.btn-default.submit-button {
  color: #333 !important;
  background-color: #fff !important;
  border-color: #ccc !important;
}

.btn.btn-primary {
  color: #fff;
  background: #24a0ed;
  border-color: #24a0ed;
}

.p-t-5 {
  padding-top: 5px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 1rem !important;
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
  right: 14px !important;
  left: auto !important;
  overflow-x: hidden;
}

/*======= Button 3 =======*/
.btn-primary-pending::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transform: scaleX(0.3);
  transform: scaleX(0.3);
  opacity: 0;
  transition: all 0.3s;
}

.btn-primary-pending:hover {
  border: 1px solid #00000094 !important;
  color: #fff !important;
}

.btn-primary-pending:hover::before {
  opacity: 1;
  background-color: #fa7a3c;
  color: #fff !important;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition: -webkit-transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02),
    opacity 0.4s;
  transition: transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02), opacity;
}

.btn {
  color: #000 !important;
  background-color: transparent !important;

  border: 1px solid #000 !important;
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transform: scaleX(0.3);
  transform: scaleX(0.3);
  opacity: 0;
  transition: all 0.3s;
}

.btn:hover {
  border: 1px solid #00000094 !important;
  color: #fff !important;
}

.btn:hover::before {
  opacity: 1;

  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition: -webkit-transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02),
    opacity 0.4s;
  transition: transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02), opacity;
}

.btn-inprogress {
  border: 1px solid #7d6eff !important;
}

.btn-inprogress:hover::before {
  background: #7d6eff !important;
}

.btn-warning {
  border: 1px solid #ffc107 !important;
}

// .btn.btn-warning.view-button:hover{ background:#ffc107 !important;
// }

.btn-secondary {
  border: 1px solid #285192 !important;
}

.btn.btn-secondary:hover::before {
  background: #285192 !important;
}

.btn-primary-pending {
  border: 1px solid #fa7a3c !important;
}

.btn-primary-pending.btn:hover::before {
  background: #fa7a3c !important;
}

.btn-primary {
  border: 1px solid #24a0ed !important;
}

.btn-info {
  border: 1px solid #54b4d3 !important;
}

.btn {
  background: #d0d0d2 !important;
}

.btn:hover {
  transition: all 0.5s ease;
  background: #fff !important;
  color: #000 !important;
}

.btn:hover span,
.btn:hover i {
  color: #000 !important;
  transition: all 0.5s ease;
}

.btn.btn-info {
  border: 0px solid #d0d0d2 !important;

  border: 1px solid #0dcaf0 !important;
}

.btn.btn-inprogress {
  border: 1px solid #7d6eff !important;
}

.btn.btn-primary-pending {
  border: 1px solid #fa7a3c !important;
}

.btn.btn-primary {
  border: 1px solid #24a0ed !important;
}

.btn.btn-warning {
  border: 1px solid #ffc107 !important;
}

.btn.btn-danger {
  border: 1px solid #dc3545 !important;
}

.btn.btn-info:hover {
  border: 1px solid #0dcaf0 !important;
  box-shadow: 0 0 10px -2px #0dcaf0;
  transition: all 0.5s ease;
}

.btn.btn-inprogress:hover {
  border: 1px solid #7d6eff !important;
  box-shadow: 0 0 10px -2px #7d6eff;
  transition: all 0.5s ease;
}

.btn.btn-primary-pending:hover {
  border: 1px solid #fa7a3c !important;
  box-shadow: 0 0 10px -2px #fa7a3c;
  transition: all 0.5s ease;
}

.btn.btn-primary:hover {
  border: 1px solid #24a0ed !important;
  box-shadow: 0 0 10px -2px #24a0ed;
  transition: all 0.5s ease;
}

.btn.btn-warning:hover {
  border: 1px solid #ffc107 !important;
  box-shadow: 0 0 10px -2px #ffc107;
  transition: all 0.5s ease;
}

.btn.btn-danger:hover {
  border: 1px solid #dc3545 !important;
  box-shadow: 0 0 10px -2px #dc3545;
  transition: all 0.5s ease;
}

.btn.btn-secondary {
  border: 1px solid #285192 !important;
}

.btn.btn-secondary:hover {
  border: 1px solid #285192 !important;
  box-shadow: 0 0 10px -2px #285192;
}

button.mat-focus-indicator.mat-button.btn-success,
.btn.btn-success {
  border: 1px solid #28a745 !important;
}

.btn.btn-success:hover,
.ion-card-subtitle.btn.btn-success:hover,
.btn.btn-success:hover {
  border: 1px solid #28a745 !important;
  box-shadow: 0 0 10px -2px #28a745;
}

button.mat-focus-indicator.mat-button {
  border: 1px solid #00000094 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.notifications-app {
  min-width: 300px;
  max-width: 380px !important;
  right: 16px !important;
  padding: 10px;
  overflow: auto;
  height: auto !important;
  overflow-x: hidden;
  margin-top: 10px;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mt-7n {
  margin-top: -7px !important;
}

.notifications-app ion-content {
  --ion-background-color: #fff !important;
}

.notifications-app {
  background-color: #fff !important;
}

.notifications-app .inner-scroll.scroll-y::-webkit-scrollbar,
.notifications-app .ion-infinite-scroll-content::-webkit-scrollbar,
.notifications-app::-webkit-scrollbar {
  width: 8px !important;
}

.notifications-app .ion-infinite-scroll-content::-webkit-scrollbar-thumb,
.notifications-app::-webkit-scrollbar-thumb {
  background: #c2c3c3 !important;
}

.notifications-app ion-content.md.content-ltr {
  height: calc(100vh - 20rem) !important;
}

.notifications-app .ion-infinite-scroll-content {
  min-height: 0px !important;
  max-height: 0px !important;
}

ion-content {
  –offset-bottom: auto !important;
  –overflow: auto;
  background: var(
    –ion-toolbar-background,
    var(–ion-background-color, #f4f5f8)
  ) !important;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}

.p-20 {
  padding: 20px !important;
}

.w-100 {
  width: 100% !important;
}

.dinlineblock {
  display: inline-block !important;
}

.text-left {
  text-align: left !important;
}

.dinlineblock .justify-content-end {
  width: 0.5em;
  height: 0.5em;
  position: relative;
  float: right;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

nav.navbar.navbar-expand-sm {
  padding: 0px !important;
  margin-top: 10px;
}

.ticket-layout .nav-tabs .nav-item .nav-link {
  border: 0px solid #fff;
  color: #000 !important;
  margin: 0px 3px;
}

.ticket-layout .nav-tabs {
  border-width: 2px;
}

.ticket-layout .nav-tabs .nav-item.show .nav-link,
.ticket-layout .nav-tabs .nav-link.active,
.ticket-layout .nav-tabs .nav-link:hover {
  color: #fff !important;
  background-color: #6e5694;
  border-color: #6e5694;
}

@media screen and (min-width: 650px) and (max-width: 700px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 640px !important;
  }

  .example-list {
    width: 100% !important;
  }
  .example-list.mx-auto {
    width: 86% !important;
  }
}

.mr-40 {
  margin-right: 40px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.cursorp {
  cursor: pointer;
}

.namelist {
  //  content:attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  border-radius: 50%;
  background: plum;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.icon-resize.navbar i {
  font-size: 1.25rem !important;
  padding: 0px 8px;
}

.dinlineblock {
  display: inline-block !important;
}

.height_scroll {
  height: calc(100vh - 18rem) !important;
}

.scroll-y::-webkit-scrollbar,
.height_scroll::-webkit-scrollbar {
  width: 0.313rem !important;
}

ion-content {
  width: calc(100% + 15px);
}

ion-content::part(scroll) {
  padding-right: 15px;

  @media screen and (max-width: 800px) {
    padding-right: 0px;
  }
}

.dropdown-menu.pt-0.pb-0 li .dropdown-item {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 5px;
}

.custom-card {
  background: rgb(255, 255, 255);
  margin-bottom: 5px;
  border-radius: 16px;
  margin-right: 20px;
  box-shadow: 0 0 20px rgb(0 0 0 / 9%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(154, 154, 154, 0.2);
}

.custom-card-2 {
  background: rgb(255, 255, 255);
  margin-bottom: 5px;
  border-radius: 16px;
  margin-right: 20px;
  box-shadow: 0 0 20px rgb(0 0 0 / 9%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 5px solid rgba(255, 0, 0, 0.2);
}

.mt-5 {
  margin-top: 5px !important;
}

.heightscroll {
  height: calc(100vh - 16rem) !important;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 800px) {
    height: calc(100vh - 11rem) !important;
  }
}

.heightaccess {
  height: calc(100vh - 16rem) !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  margin: 0 0 0px !important;
}

.mat-mdc-dialog-actions.mat-mdc-dialog-actions-align-end,
.mat-mdc-dialog-actions[align="end"] {
  padding-bottom: 20px !important;
  padding-right: 20px !important;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px !important;
}

.time_picker .form-group input {
  height: 40px;
  background: whitesmoke;
}

.l-12px span.mdc-button__label {
  left: -12px !important;

  @media screen and (max-width: 800px) {
    left: -17px !important;
  }
}

.ion-card-subtitle.btn.btn-success .fa-close {
  top: 2px;
}
.text-transform-capitalize,
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  text-transform: capitalize;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding-top: 20px;
  padding-right: 20px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix,
.time_picker
  .mat-form-field
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-mdc-form-field-infix {
  padding-top: 8px !important;
}

.time_picker .mat-mdc-form-field-flex {
  height: 40px !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
// .inner-scroll.scroll-y::-webkit-scrollbar,
// .heightscroll::-webkit-scrollbar ,
// .mat-mdc-dialog-content::-webkit-scrollbar {
//   width: 0.25rem !important;
// }
// .inner-scroll.scroll-y::-webkit-scrollbar-thumb,
// .heightscroll::-webkit-scrollbar-thumb ,
// .mat-mdc-dialog-content::-webkit-scrollbar-thumb {
//     background: #c2c3c3 !important;
// }
::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 0.35rem !important;
}
::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: #c2c3c3 !important;
}
// .nav-department::-webkit-scrollbar{
//   width: 0.3rem !important;
// }
.nav-department.dropdown-menu {
  height: calc(100vh - 44rem) !important;
  overflow: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 800px) {
  //   ion-accordion-group > ion-accordion:last-of-type ion-item[slot=header]{
  //   --padding-start:3px ;
  // }
  // ion-accordion-group .item-inner{
  //   padding-inline-end: 0.4px;
  // }
  ion-accordion.accordion-animated
    > [slot="header"]
    .ion-accordion-toggle-icon {
    padding: 0px;
    font-size: 14px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    overflow: visible;
  }
  .item.sc-ion-label-md-h,
  .item .sc-ion-label-md-h {
    white-space: break-spaces !important;
    overflow: visible !important;
  }
}

.chart-heading {
  position: relative;
  top: -50px;
  left: -80px;
}

.mb-15 {
  margin-bottom: 15px !important;
}
.relative {
  position: relative;
}
.noHover{
  pointer-events: none;
}
.swal2-container {
  height: 100vh !important;
  position: relative !important;
}
.time_picker {
  .picker {
    mat-form-field {
      .mat-mdc-text-field-wrapper {
        background-color: white !important;
        border-bottom: 0px !important;
        border: 1px solid #ccc !important;
      }
      .mdc-text-field  {
        border: 1px solid #ccc !important;
        border-radius: 4px !important;
      }
    }
  }
}
